@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap";
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto;
}

#map_image {
  z-index: 0;
  width: 100%;
  height: 100%;
}

.fill {
  width: 100%;
  height: 100%;
}

.onlyonload {
  display: none;
}

.loading > .onlyonload {
  display: block;
}

.loading > .waitload {
  display: none;
}

.load-spinner {
  width: 0;
  height: 0;
  border: 20px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 100%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    top: 50%;
    left: 50%;
    transform: rotate(0deg);
  }

  100% {
    top: 50%;
    left: 50%;
    transform: rotate(360deg);
  }
}

.full-center {
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.outermap {
  display: inline-block;
  position: relative;
}

.pin {
  width: 1em;
  height: 1em;
  text-align: center;
  background-color: #fff;
  border: 5px solid red;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=index.f069ddd6.css.map */
