@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

//@import "../node_modules/@fontsource/roboto/index.css";

body {
    font-family: Roboto;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
}

#map_image {
    z-index: 0;
    width: 100%;
    height: 100%;
}

.fill {
    width: 100%;
    height: 100%;
}

/*#renderer {
    width: 100%;
    height: 100%;
}

iframe {
    border: 5px solid;
    margin: auto;
    width: 50%;
    height: 50%
}*/


.onlyonload {
    display: none;
}

.loading > .onlyonload {
    display: block;
}

.loading > .waitload {
    display: none
}

.load-spinner {
    border: 20px solid #f3f3f3; /* Light grey */
    border-top: 20px solid #3498db; /* Blue */
    border-radius: 100%;
    width: 0;
    height: 0;
    animation: spin 2s linear infinite;
  }
  
@keyframes spin {
    0% { transform: rotate(0deg);  top: 50%; left: 50%;}
    100% { transform: rotate(360deg); top:50%; left: 50%; }
}

.full-center {
    position: absolute;
    margin: 0;
    top: 50%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
}

.outermap {
  position: relative;
  display: inline-block;
}

.pin {
    position: absolute;
    border: 5px solid red;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    text-align: center;
    background-color: white;
    transform: translate(-50%, -50%);
}